import React, { FunctionComponent } from "react";
// Components
import { classNames } from "@/utils/classNames";

type Props = {
  value?: string;
  containerClass?: string;
  size?: "SMALL" | "MEDIUM";
};

export const NotificationBall: FunctionComponent<Props> = ({
  value = "",
  containerClass,
  size = "SMALL",
}) => {
  const sizes = {
    SMALL: "h-3 w-3",
    MEDIUM: "h-4 w-4",
  };
  return (
    <div
      className={classNames(
        "rounded-full bg-[#40B76A]",
        sizes[size],
        containerClass
      )}
    >
      <p className="text-[9px] absolute bottom-[-0.5px] left-[3.5px]">
        {value}
      </p>
    </div>
  );
};
