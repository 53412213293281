import { GetIcon } from "@/components/common/icon";
import { desktop } from "@/utils/hooks/useMediaQuery";
import { useMediaQuery } from "@mui/material";
import {
  FunctionComponent,
  useRef,
  useState,
  useContext,
  useEffect,
  ChangeEvent,
} from "react";
import useClickOutside from "@/utils/hooks/useClickOutside";
import UserContext from "@/context/user";
import { useRouter } from "next/router";
import KumuButtonComponent from "@/components/layout/kumu/button-component";
import { KUMU_URLS } from "@/utils/urls/kumu";
import { useAuth } from "@/context/auth/hooks";
import useTranslation from "next-translate/useTranslation";
import { Link } from "@/components/common/link";
import MainLayoutWrapper from "../main-layout-wrapper";
import { useKumuModeContext } from "@/context/kumu-mode";
import { KumuSearchInput } from "../kumu-search-input";
import { NotificationBall } from "@/components/common/notification-ball";
import { clientFetchService } from "@/services/fetch";
import { SIXTY_URLS } from "@/utils/urls";
import { classNames } from "@/utils/classNames";
import LogOut from "../kumu-logout";
import { useUploaderContext } from "@/context/uploader";
import { consoleSandbox } from "@sentry/utils";
import { Label } from "@/components/common/label";

const getUserInitials = (user: User) =>
  user.name && user.lastName
    ? `${user?.name?.slice(0, 1).toUpperCase() ?? ""}${
        user?.lastName?.slice(0, 1).toUpperCase() ?? ""
      }`
    : user.creator && user.creator.name && user.creator.lastName
      ? `${user?.creator.name?.slice(0, 1).toUpperCase() ?? ""}${
          user?.creator.lastName?.slice(0, 1).toUpperCase() ?? ""
        }`
      : `${user?.fullName?.slice(0, 2).toUpperCase() ?? ""}`;

const MyProfile: FunctionComponent<{ isCreator: boolean }> = ({
  isCreator = false,
}: {
  isCreator?: boolean;
}) => {
  const router = useRouter();
  const { kumuMode } = useKumuModeContext();
  const handleProfile = async () => {
    if (kumuMode == "expert") router.push(KUMU_URLS.PAGES.B2B.SETUP_PROFILE);
    else router.push(KUMU_URLS.PAGES.B2C.SETUP_PROFILE.MAIN);
  };

  return (
    <>
      <li
        className="h-9 hover:bg-[#40b76a] hover:rounded-[8px] font-bold  active:bg-[#40b76a] text-black text-[13px] p-[8px_20px_8px_16px] cursor-pointer z-[999]"
        onClick={handleProfile}
      >
        My account
      </li>
    </>
  );
};

const MainNavbar: FunctionComponent = ({}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { user } = useContext(UserContext);
  const isDesktop = useMediaQuery(desktop);
  const router = useRouter();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleUploads, setToggleUploads] = useState(false);
  const { kumuMode, setKumuMode } = useKumuModeContext();

  const { uploadFile } = useUploaderContext();
  const prevUploadFileRef = useRef(uploadFile);
  const areFilesEqualIgnoringProgress = (prevFiles: any, newFiles: any) => {
    if (prevFiles.length !== newFiles.length) return false;
    for (let i = 0; i < prevFiles.length; i++) {
      const { progress: prevProgress, ...prevRest } = prevFiles[i];
      const { progress: newProgress, ...newRest } = newFiles[i];
      if (JSON.stringify(prevRest) !== JSON.stringify(newRest)) return false;
    }
    return true;
  };

  useEffect(() => {
    if (!areFilesEqualIgnoringProgress(prevUploadFileRef.current, uploadFile)) {
      console.log(uploadFile.length);
      uploadFile.length > 0 && setToggleUploads(true);
      prevUploadFileRef.current = uploadFile;
    }
  }, [uploadFile]);

  const [unreadChats, setUnreadChats] = useState<number>(0);
  useEffect(() => {
    let mode = localStorage.getItem("mode");
    if (!mode) {
      if (router.asPath.includes("dashboard")) {
        localStorage.setItem("mode", "expert");
        setKumuMode("expert");
      } else {
        localStorage.setItem("mode", "member");
        setKumuMode("member");
      }
    } else {
      if (mode === "expert") {
        setKumuMode("expert");
      } else {
        setKumuMode("member");
      }
    }
  }, [, router]);

  useEffect(() => {
    if (user.id) getUnreadChats();
  }, []);

  const getUnreadChats = async () => {
    const unreadMessages = await clientFetchService<number>(
      SIXTY_URLS.API.CHAT.GET_UNREAD_CHATS,
      {
        method: "GET",
      }
    );
    setUnreadChats(unreadMessages);
  };

  const menuRef = useRef(null);
  const ref = useRef(null);
  useClickOutside(ref, () => setMenuVisible(false));
  useClickOutside(menuRef, () => setToggleMenu(false));

  const handleClick = (mode: string) => {
    if (mode === "member") {
      localStorage.setItem("mode", "member");
      setKumuMode("member");
      router.push(KUMU_URLS.PAGES.B2C.MAIN);
    } else {
      localStorage.setItem("mode", "expert");
      setKumuMode("expert");
      router.push(KUMU_URLS.PAGES.B2B.MAIN);
    }
  };

  const [search, setSearch] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const onSearch = (value: string) => {
    setIsSearching(true);
  };

  useEffect(() => {
    if (isSearching) {
      router.push(KUMU_URLS.PAGES.B2C.CREATORS_SEARCH.SEARCH_QUERY(search));
    }
  }, [isSearching]);
  const hasSubscriptions = (user.subscription ?? []).length > 0;
  const hideShowSearchbar = router.asPath === "/" && !hasSubscriptions;

  const isMember = kumuMode === "member" || !user.creator;
  const hasSearchBar =
    isMember && !router.asPath.includes("expert-search") && !hideShowSearchbar;

  const hideNotificationBall = router.asPath.includes("messages");

  const chatsToRead = unreadChats > 0 && unreadChats ? unreadChats : 0;
  return (
    <div
      className={classNames(
        "w-full flex justify-between desktop:justify-end py-5 relative px-4 desktop:px-[72px] ultrawide:px-[241px] items-end",
        menuVisible && " bg-[#C9D4CA]"
      )}
    >
      {!isDesktop ? (
        <section className="flex justify-center items-center gap-3 ">
          <div className="cursor-pointer" ref={ref}>
            <div onClick={() => setMenuVisible(!menuVisible)}>
              {menuVisible ? (
                <GetIcon
                  iconName="close"
                  className={classNames(
                    "fill-current h-[20px] m-[6px] w-[20px] stroke-current stroke-1"
                  )}
                />
              ) : (
                <GetIcon iconName="kumu-hamburguer-menu" />
              )}
            </div>
            {menuVisible ? (
              <div className="fixed z-50 top-16 left-0 w-screen">
                <MainLayoutWrapper
                  alwaysVisible
                  dropDownMode
                  searchBar={
                    <KumuSearchInput
                      className="max-w-[400px] w-full mr-auto mb-8"
                      placeholder={"Find an expert"}
                      onChange={onChange}
                      onSearch={() => onSearch(search)}
                      onReset={() => setSearch("")}
                      dark={false}
                      value={search}
                      disabled={isSearching}
                    />
                  }
                />
              </div>
            ) : null}
          </div>
          <Link
            href={
              kumuMode === "expert"
                ? KUMU_URLS.PAGES.B2B.MAIN
                : KUMU_URLS.PAGES.B2C.MAIN
            }
          >
            <GetIcon iconName="kumu-icon" className="fill-black" />
          </Link>
        </section>
      ) : null}

      {hasSearchBar && user.id && isDesktop && (
        <>
          <KumuSearchInput
            className="max-w-[400px] w-full mr-auto"
            placeholder={"Find an expert"}
            onChange={onChange}
            onSearch={() => onSearch(search)}
            onReset={() => setSearch("")}
            dark={false}
            value={search}
            disabled={isSearching}
          />
        </>
      )}

      <section className="relative flex gap-2 h-[36px]">
        {user.creator && isDesktop && (
          <div className="flex items-center">
            {kumuMode === "expert" ? (
              <KumuButtonComponent
                className="text-[10px] font-extrabold cursor-pointer hover:opacity-70"
                theme="white"
                onClick={() => handleClick("member")}
              >
                SWITCH TO MEMBER
              </KumuButtonComponent>
            ) : (
              <KumuButtonComponent
                className="text-[10px] font-extrabold cursor-pointer hover:opacity-70"
                theme="white"
                onClick={() => handleClick("expert")}
              >
                SWITCH TO EXPERT
              </KumuButtonComponent>
            )}
          </div>
        )}

        {user.id && (
          <>
            <Link
              className={classNames(
                " w-9 h-9 rounded-full font-extrabold flex items-center cursor-pointer relative justify-center transition-all duration-300",
                menuVisible ? "bg-white" : "bg-light-green-leaf"
              )}
              href={
                kumuMode === "expert"
                  ? KUMU_URLS.PAGES.B2B.MESSAGES
                  : KUMU_URLS.PAGES.B2C.MESSAGES.MAIN
              }
            >
              {chatsToRead > 0 && !hideNotificationBall && (
                <NotificationBall
                  containerClass="absolute top-0 left-6 "
                  value={chatsToRead.toString()}
                />
              )}

              <GetIcon iconName="kumu-messages" className="fill-black" />
            </Link>
            {uploadFile.length > 0 && (
              <div
                className={classNames(
                  " w-9 h-9 rounded-full font-extrabold flex items-center cursor-pointer relative justify-center transition-all duration-300",
                  menuVisible ? "bg-white" : "bg-light-green-leaf"
                )}
                onClick={() => setToggleUploads(!toggleUploads)}
                ref={menuRef}
              >
                <NotificationBall
                  containerClass="absolute top-0 left-6 text-center"
                  value={uploadFile.length.toString()}
                />

                <GetIcon
                  iconName="kumu-arrow-icon"
                  className="w-[20px] rotate-180"
                />
                {toggleUploads ? (
                  <ul className="bg-[#f1f1f1] min-w-[300px] max-w-[400px] flex flex-col justify-center  absolute right-5 top-[40px] rounded-[8px] shadow-[0_10px_22px_rgba(0,0,0,0.3)] opacity-100 z-30  whitespace-nowrap hover:opacity-100 ">
                    <div className="mx-4 mt-4">
                      <Label textAlignment="left" fontWeight="semibold">
                        Uploading files
                      </Label>
                      <Label
                        textAlignment="left"
                        fontSize="[14px]"
                        className="text-pretty mt-2"
                      >
                        Your video is still uploading. Keep this browser tab
                        open until the upload is complete.
                      </Label>
                    </div>
                    {uploadFile.length > 0 &&
                      uploadFile.map((el) => (
                        <div
                          key={el.phisicalName}
                          className="flex flex-col border-[#C9D4CA] border-b pb-3 mx-4 mt-4 "
                        >
                          <div className="flex row justify-between ">
                            <span className="text-black text-sm  ">
                              {el.fileName}
                            </span>
                            <span className="text-[#40B869] text-sm ">
                              {<>{el.progress.toFixed(0)}% completed</>}
                            </span>
                          </div>
                          <div
                            className={classNames(
                              "bg-[#40B76A] leading-none rounded-full h-1 mt-2 self-start",
                              "flex"
                            )}
                            style={{ width: `${el.progress.toFixed(0)}%` }}
                          />
                        </div>
                      ))}

                    <div className=" flex flex-col  mx-4 my-4 items-end">
                      <Label
                        fontSize="[14px]"
                        className="underline"
                        onClick={() => setToggleUploads(!toggleUploads)}
                      >
                        Close
                      </Label>
                    </div>
                  </ul>
                ) : null}
              </div>
            )}
            <div
              className={classNames(
                " w-9 h-9 rounded-full font-extrabold flex items-center cursor-pointer relative justify-center  transition-all duration-300 z-[999]",
                !toggleMenu && "hover:opacity-80",
                user.creator?.status == "PRIVATE"
                  ? "bg-[#914A53]"
                  : "bg-[#CCC3DD]"
              )}
              onClick={() => setToggleMenu(!toggleMenu)}
              ref={menuRef}
            >
              <p
                className={classNames(
                  "text-sm leading-none",
                  user.creator?.status == "PRIVATE"
                    ? "text-white"
                    : "text-graphite"
                )}
              >
                {getUserInitials(user)}
              </p>
              {toggleMenu ? (
                <ul className="bg-[#f1f1f1] min-w-[100px] flex flex-col justify-center gap-y-1.5 absolute right-5 top-[40px] rounded-[8px] shadow-[0_10px_22px_rgba(0,0,0,0.3)] opacity-100 z-30  whitespace-nowrap hover:opacity-100">
                  <MyProfile isCreator={!!user.creator} />
                  {isDesktop && <LogOut />}
                </ul>
              ) : null}
            </div>
          </>
        )}
        {user.creator?.status == "PRIVATE" && (
          <p className="text-[#914A53] text-[10px] font-bold content-center">
            OFFLINE
          </p>
        )}
      </section>
    </div>
  );
};

export default MainNavbar;
